import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Nunito_Sans\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"nunito\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/atoms/accordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Calendar"] */ "/vercel/path0/src/components/atoms/calendar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel","CarouselContent","CarouselItem","CarouselPrevious","CarouselNext"] */ "/vercel/path0/src/components/atoms/carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ChartContainer","ChartTooltip","ChartTooltipContent"] */ "/vercel/path0/src/components/atoms/chart.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Bars","LineCharts","ChartPie","BarOrLineChart"] */ "/vercel/path0/src/components/atoms/charts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Checkbox"] */ "/vercel/path0/src/components/atoms/checkbox.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Combobox"] */ "/vercel/path0/src/components/atoms/combobox.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Command","CommandEmpty","CommandGroup","CommandInput","CommandItem","CommandList","CommandSeparator"] */ "/vercel/path0/src/components/atoms/command.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Dialog","DialogPortal","DialogOverlay","DialogClose","DialogTrigger","DialogContent","DialogHeader","DialogFooter","DialogTitle","DialogDescription"] */ "/vercel/path0/src/components/atoms/dialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownMenu","DropdownMenuTrigger","DropdownMenuContent","DropdownMenuItem","DropdownMenuCheckboxItem","DropdownMenuRadioItem","DropdownMenuLabel","DropdownMenuSeparator","DropdownMenuShortcut","DropdownMenuGroup","DropdownMenuPortal","DropdownMenuSub","DropdownMenuSubContent","DropdownMenuSubTrigger","DropdownMenuRadioGroup"] */ "/vercel/path0/src/components/atoms/dropdown-menu.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/atoms/hover-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Label"] */ "/vercel/path0/src/components/atoms/label.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Popover","PopoverTrigger","PopoverContent"] */ "/vercel/path0/src/components/atoms/popover.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RadioGroup","RadioGroupItem"] */ "/vercel/path0/src/components/atoms/radio-group.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RangeCalendar"] */ "/vercel/path0/src/components/atoms/range-calendar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ResizablePanelGroup","ResizablePanel","ResizableHandle"] */ "/vercel/path0/src/components/atoms/resizable.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollArea","ScrollBar"] */ "/vercel/path0/src/components/atoms/scroll-area.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SelectGroup","SelectLabel","Select","SelectContent","SelectItem","SelectTrigger","SelectValue"] */ "/vercel/path0/src/components/atoms/select.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Separator"] */ "/vercel/path0/src/components/atoms/separator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Sheet","SheetPortal","SheetOverlay","SheetTrigger","SheetClose","SheetContent","SheetHeader","SheetFooter","SheetTitle","SheetDescription"] */ "/vercel/path0/src/components/atoms/sheet.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Sidebar","SidebarContent","SidebarFooter","SidebarGroup","SidebarGroupAction","SidebarGroupContent","SidebarGroupLabel","SidebarHeader","SidebarInput","SidebarInset","SidebarMenu","SidebarMenuAction","SidebarMenuBadge","SidebarMenuButton","SidebarMenuItem","SidebarMenuSkeleton","SidebarMenuSub","SidebarMenuSubButton","SidebarMenuSubItem","SidebarProvider","SidebarRail","SidebarSeparator","SidebarTrigger","useSidebar"] */ "/vercel/path0/src/components/atoms/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Switch"] */ "/vercel/path0/src/components/atoms/switch.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Tabs","TabsList","TabsTrigger","TabsContent"] */ "/vercel/path0/src/components/atoms/tabs.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/atoms/tooltip.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Archive"] */ "/vercel/path0/src/components/molecules/archives.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Avatar"] */ "/vercel/path0/src/components/molecules/avatar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Banner"] */ "/vercel/path0/src/components/molecules/banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ButtonLoading"] */ "/vercel/path0/src/components/molecules/button-loading.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["getSheets","getTables"] */ "/vercel/path0/src/components/molecules/dailies-statistics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DatePickerWithRange"] */ "/vercel/path0/src/components/molecules/date-range-picker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DialogMultiuse"] */ "/vercel/path0/src/components/molecules/dialog-multiuse.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/molecules/form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FreemiumBanner"] */ "/vercel/path0/src/components/molecules/freemium-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Nav"] */ "/vercel/path0/src/components/molecules/nav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DatePicker"] */ "/vercel/path0/src/components/molecules/params/date-picker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Offices"] */ "/vercel/path0/src/components/molecules/params/offices.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PathBreadcrumb"] */ "/vercel/path0/src/components/molecules/path-breadcrumb.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProductCard"] */ "/vercel/path0/src/components/molecules/products-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DaysOfWeek"] */ "/vercel/path0/src/components/molecules/schedule-days-week.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AdministrationSidebar"] */ "/vercel/path0/src/components/molecules/sidebars/administration/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ConfigurationSidebar"] */ "/vercel/path0/src/components/molecules/sidebars/configuration/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MyAccountSidebar"] */ "/vercel/path0/src/components/molecules/sidebars/my-account/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PatientsSidebar"] */ "/vercel/path0/src/components/molecules/sidebars/patients/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReportsSidebar"] */ "/vercel/path0/src/components/molecules/sidebars/reports/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StatisticsSidebar"] */ "/vercel/path0/src/components/molecules/sidebars/statistics/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StaticSearchBar"] */ "/vercel/path0/src/components/molecules/static-search-bar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/src/components/molecules/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useAutoSubmit"] */ "/vercel/path0/src/hooks/use-auto-submit.ts");
;
import(/* webpackMode: "eager", webpackExports: ["useDebouncedSearch"] */ "/vercel/path0/src/hooks/use-debounce-search.ts");
;
import(/* webpackMode: "eager", webpackExports: ["useDebounce"] */ "/vercel/path0/src/hooks/use-debounce.ts");
;
import(/* webpackMode: "eager", webpackExports: ["useKeypress"] */ "/vercel/path0/src/hooks/use-keypress.ts");
;
import(/* webpackMode: "eager", webpackExports: ["useOnClickOutside"] */ "/vercel/path0/src/hooks/use-on-click-outside.ts");
;
import(/* webpackMode: "eager", webpackExports: ["usePagination"] */ "/vercel/path0/src/hooks/use-pagination.ts");
;
import(/* webpackMode: "eager", webpackExports: ["usePermissionsView"] */ "/vercel/path0/src/hooks/use-permissions.ts");
;
import(/* webpackMode: "eager", webpackExports: ["useScheduleUpdate"] */ "/vercel/path0/src/hooks/use-schedule-update.ts");
